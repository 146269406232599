<template>
  <div
    class="has-nav-bar view-user-message"
    :class="{ 'show-bottom-popup': editable }"
  >
    <van-nav-bar title="系统消息" fixed left-arrow @click-left="onClickLeft">
      <template #right>
        <span v-if="!editable" @click="onClickRight">
          <van-icon class-prefix="iconfont" name="edit"></van-icon>
        </span>
        <span v-else @click="onCancelRight">
          <van-icon class-prefix="iconfont" name="cancle"></van-icon>
        </span>
      </template>
    </van-nav-bar>
    <van-icon
      class-prefix="iconfont"
      name="guolv"
      class="filter-btn"
      @click="filterHandle"
    >
    </van-icon>
    <van-tabs v-model="activeName">
      <van-tab
        title="未读"
        name="unread"
        :badge="unreadData.total ? unreadData.total : ''"
      >
        <van-pull-refresh v-model="unreadRefreshing" @refresh="onUnreadRefresh">
          <van-checkbox-group v-model="unreadCheckboxModel">
            <van-list
              v-model="unreadLoading"
              :finished="unreadFinished"
              finished-text="没有更多了"
              :error.sync="unreadError"
              error-text="加载失败，请稍后再试！"
              @load="onUnreadLoad"
            >
              <van-swipe-cell
                v-for="item in unreadData.list"
                :key="'unread-' + item.id"
              >
                <van-cell @click.stop="toDetail(item)">
                  <template v-if="editable" #title>
                    <van-checkbox :name="item.id">
                      <div>
                        <i></i>
                        <span>
                          <span v-if="item.messageType === 1" class="urge-tag"
                            >催办</span
                          >
                          <span
                            style="vertical-align: middle;"
                            v-html="item.content"
                          ></span>
                        </span>
                      </div>
                    </van-checkbox>
                  </template>
                  <template v-else #title>
                    <div>
                      <i></i>
                      <span>
                        <span v-if="item.messageType === 1" class="urge-tag"
                          >催办</span
                        >
                        <span
                          style="vertical-align: middle;"
                          v-html="item.content"
                        ></span>
                      </span>
                    </div>
                  </template>
                  <template #label>
                    <span>{{ item.createTime }}</span>
                  </template>
                </van-cell>
                <template #right>
                  <van-button
                    square
                    type="primary"
                    text="已读"
                    @click="readNoticeHandle(item.id)"
                  />
                  <van-button
                    square
                    type="danger"
                    text="删除"
                    @click="deleteNoticeHandle(item.id)"
                  />
                </template>
              </van-swipe-cell>
            </van-list>
          </van-checkbox-group>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="已读" name="read">
        <van-pull-refresh v-model="readRefreshing" @refresh="onReadRefresh">
          <van-checkbox-group v-model="readCheckboxModel">
            <van-list
              v-model="readLoading"
              :finished="readFinished"
              finished-text="没有更多了"
              :error.sync="readError"
              error-text="加载失败，请稍后再试！"
              @load="onReadLoad"
            >
              <van-swipe-cell
                v-for="item in readData.list"
                :key="'read-' + item.id"
              >
                <van-cell @click.stop="toDetail(item)">
                  <template v-if="editable" #title>
                    <van-checkbox :name="item.id">
                      <span>
                        <span v-if="item.messageType === 1" class="urge-tag"
                          >催办</span
                        >
                        <span
                          style="vertical-align: middle;"
                          v-html="item.content"
                        ></span>
                      </span>
                    </van-checkbox>
                  </template>
                  <template v-else #title>
                    <div>
                      <span>
                        <span v-if="item.messageType === 1" class="urge-tag"
                          >催办</span
                        >
                        <span
                          style="vertical-align: middle;"
                          v-html="item.content"
                        ></span>
                      </span>
                    </div>
                  </template>
                  <template #label>
                    <span>{{ item.createTime }}</span>
                  </template>
                </van-cell>
                <template #right>
                  <van-button
                    square
                    type="danger"
                    text="删除"
                    @click="deleteNoticeHandle(item.id)"
                  />
                </template>
              </van-swipe-cell>
            </van-list>
          </van-checkbox-group>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
    <van-popup
      v-model="editPopupStatus"
      position="bottom"
      :overlay="false"
      :lock-scroll="false"
      class="edit-popup"
    >
      <van-row v-if="isAllType" type="flex" justify="space-around">
        <span v-if="activeName === 'unread'" @click="readAllCheckboxHandle"
          >全部已读</span
        >
        <span @click="deleteAllCheckboxHandle">全部删除</span>
      </van-row>
      <van-row v-else type="flex" justify="space-around">
        <span v-if="activeName === 'unread'" @click="readCheckboxHandle"
          >已读</span
        >
        <span @click="deleteCheckboxHandle">删除</span>
      </van-row>
    </van-popup>
    <van-popup
      v-model="filterPopupStatus"
      position="bottom"
      class="filter-popup"
    >
      <div class="popup-content">
        <div>创建时间</div>
        <div>
          <date-time v-model="filterDate" type="date"></date-time>
        </div>
        <div class="not-sticky">业务类型</div>
        <van-radio-group v-model="filter.moduleId" direction="horizontal">
          <van-radio
            v-for="item in moduleList"
            :key="item.value"
            :name="item.value"
          >
            {{ item.label }}
          </van-radio>
        </van-radio-group>
      </div>
      <div class="popup-footer">
        <van-button type="danger" class="reset-btn" @click="resetHandle">
          重置
        </van-button>
        <van-button type="info" class="handle-btn" @click="submitHandle">
          确定
        </van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import { mapActions } from "vuex";
import messageApi from "../../api/message";
import { parse } from "querystring";

export default {
  name: "UserMessage",
  data() {
    return {
      activeName: "unread",
      size: 15,
      editable: false,
      editPopupStatus: false,
      unreadLoading: false,
      unreadError: false,
      unreadFinished: false,
      unreadRefreshing: false,
      unreadPage: 1,
      unreadCheckboxModel: [],
      unreadData: {
        total: 0,
        list: []
      },
      readLoading: false,
      readError: false,
      readFinished: false,
      readRefreshing: false,
      readPage: 1,
      readCheckboxModel: [],
      readData: {
        total: 0,
        list: []
      },
      filterPopupStatus: false,
      filter: {},
      filterDate: {
        start: "",
        end: ""
      },
      moduleList: []
    };
  },
  computed: {
    isAllType() {
      if (this.activeName === "unread") {
        return !this.unreadCheckboxModel.length;
      } else {
        return !this.readCheckboxModel.length;
      }
    }
  },
  created() {
    this.initModuleList();
  },
  methods: {
    ...mapActions(["getMessageCountNoticesAction"]),
    filterHandle() {
      this.filterPopupStatus = true;
    },
    resetHandle() {
      this.filter = {};
      this.filterDate = {};
    },
    submitHandle() {
      if (this.activeName === "unread") {
        this.onUnreadRefresh();
      } else if (this.activeName === "read") {
        this.onReadRefresh();
      }
      this.filterPopupStatus = false;
    },
    initModuleList() {
      const params = {
        type: 2
      };
      messageApi.getMessageModulesByType(params).then(res => {
        this.moduleList = res;
      });
    },
    onClickLeft() {
      this.$router.back();
    },
    // 开始编辑
    onClickRight() {
      this.editable = true;
      this.editPopupStatus = true;
    },
    // 取消编辑
    onCancelRight() {
      this.editable = false;
      this.editPopupStatus = false;
    },
    toDetail(item) {
      if (!item.isAllowJump) {
        Toast({
          message: "该功能模块业务复杂，\n请在电脑上处理！",
          position: "top"
        });
        return;
      }
      if (!this.editable) {
        let query = { messageId: item.id };
        if (item.uri.includes("?")) {
          const str = item.uri.split("?")[1];
          query = { ...query, ...parse(str) };
        }
        this.$router.push({ path: item.uri, query });
      }
    },
    // 读全部
    readAllCheckboxHandle() {
      this.readNoticeHandle();
    },
    // 读多条
    readCheckboxHandle() {
      this.readNoticeHandle(this.unreadCheckboxModel);
    },
    // 删除全部
    deleteAllCheckboxHandle() {
      this.deleteNoticeHandle();
    },
    // 删除多条
    deleteCheckboxHandle() {
      const deleteList =
        this.activeName === "unread"
          ? this.unreadCheckboxModel
          : this.readCheckboxModel;
      this.deleteNoticeHandle(deleteList);
    },
    onUnreadLoad() {
      this.getUnreadData();
    },
    onReadLoad() {
      this.getReadData();
    },
    // 刷新未读列表
    onUnreadRefresh() {
      this.unreadPage = 1;
      this.unreadFinished = false;
      this.unreadLoading = true;
      this.onUnreadLoad();
    },
    // 刷新已读列表
    onReadRefresh() {
      this.readPage = 1;
      this.readFinished = false;
      this.readLoading = true;
      this.onReadLoad();
    },
    // 同步多条未读消息到已读
    syncData(ids) {
      // 执行全部
      if (!ids) {
        this.readData.list = this.unreadData.list.concat(this.readData.list);
        this.unreadData.list = [];
        this.unreadData.total = 0;
      } else {
        for (let i = 0; i < this.unreadData.list.length; i++) {
          const item = this.unreadData.list[i];
          if (ids.indexOf(item.id) > -1) {
            this.unreadData.list.splice(i, 1);
            this.readData.list.unshift(item);
            this.unreadData.total--;
            i--;
          }
        }
      }

      if (this.activeName === "unread") {
        this.unreadCheckboxModel = [];
      } else if (this.activeName === "read") {
        this.readCheckboxModel = [];
      }
      this.editable = false;
      this.editPopupStatus = false;
      this.getMessageCountNoticesAction();
    },
    // 读多条消息
    readNoticeHandle(ids) {
      messageApi
        .updateBasePageNotices({
          ids: Array.isArray(ids) ? ids.join(",") : ids
        })
        .then(res => {
          if (res) {
            if (ids) {
              this.syncData(Array.isArray(ids) ? ids : [ids]);
            } else {
              this.syncData();
            }
          }
        });
    },
    // 同步删除消息、总数
    syncDeleteData(ids) {
      // 执行全部
      if (!ids) {
        this.unreadData.list = [];
        this.unreadData.total = 0;
        this.readData.list = [];
        this.readData.total = 0;
      } else {
        const type = this.activeName === "unread" ? "unreadData" : "readData";
        for (let i = 0; i < this[type].list.length; i++) {
          const item = this[type].list[i];
          if (ids.indexOf(item.id) > -1) {
            this[type].list.splice(i, 1);
            this[type].total--;
            i--;
          }
        }
      }
      this.editable = false;
      this.editPopupStatus = false;
      this.getMessageCountNoticesAction();
    },
    // 删除消息
    deleteNoticeHandle(ids) {
      messageApi
        .deleteBasePageNotices({
          ids: Array.isArray(ids) ? ids.join(",") : ids
        })
        .then(res => {
          if (res) {
            if (ids) {
              this.syncDeleteData(Array.isArray(ids) ? ids : [ids]);
            } else {
              this.syncDeleteData();
            }
          }
        });
    },
    getUnreadData() {
      this.unreadLoading = true;
      messageApi
        .getBasePageNotices({
          status: 0,
          page: this.unreadPage,
          size: this.size,
          ...this.filter,
          startTime: this.filterDate.start,
          endTime: this.filterDate.end
        })
        .then(res => {
          if (this.unreadRefreshing) {
            this.unreadData.list = [];
            this.unreadRefreshing = false;
          }
          this.unreadData.total = res.total;
          this.unreadData.list =
            this.unreadPage === 1
              ? res.list
              : this.unreadData.list.concat(res.list);
          this.unreadLoading = false;
          if (res.total > this.unreadPage * this.size) {
            this.unreadPage++;
          } else {
            this.unreadFinished = true;
          }
        })
        .catch(err => {
          this.unreadLoading = false;
          this.unreadRefreshing = false;
          this.unreadError = true;
        });
    },
    getReadData() {
      this.readLoading = true;
      messageApi
        .getBasePageNotices({
          status: 1,
          page: this.readPage,
          size: this.size,
          ...this.filter,
          startTime: this.filterDate.start,
          endTime: this.filterDate.end
        })
        .then(res => {
          if (this.readRefreshing) {
            this.readData.list = [];
            this.readRefreshing = false;
          }

          this.readData.total = res.total;
          this.readData.list = this.readData.list.concat(res.list);
          this.readLoading = false;
          if (res.total > this.readPage * this.size) {
            this.readPage++;
          } else {
            this.readFinished = true;
          }
        })
        .catch(() => {
          this.readLoading = false;
          this.readRefreshing = false;
          this.readError = true;
        });
    }
  },
  watch: {}
};
</script>
<style lang="scss">
.view-user-message {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  &.show-bottom-popup {
    padding-bottom: 48px;
  }
  .van-nav-bar__right {
    color: #fff;
  }
  .van-tabs {
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .van-tabs__content {
    overflow: hidden;
    overflow-y: auto;
    flex: 1;
  }
  .van-tab__text {
    .van-tag {
      vertical-align: text-bottom;
      margin-left: 7px;
    }
  }
  .van-cell {
    color: #3b4664;
    font-size: 15px;
    /*padding-top: 20px;*/
    /*padding-bottom: 20px;*/
  }
  .van-cell__title {
    > div:first-of-type {
      display: flex;
      flex-direction: row;
      height: 48px;
      > span {
        height: 48px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        a {
          color: $--color-link;
        }
      }
      > i {
        width: 22px;
        display: block;
        &:after {
          content: "";
          width: 7px;
          height: 7px;
          background: #ff0000;
          border-radius: 100%;
          display: inline-block;
        }
      }
    }
  }
  .van-cell__label {
    text-align: right;
    font-size: 15px;
    color: #aeb3c0;
  }
  .van-swipe-cell__right {
    .van-button {
      height: 100%;
    }
  }
  .edit-popup {
    height: 48px;
    line-height: 48px;
    background: #f9f9f9;
    border-top: 1px solid #ececec;
    span {
      color: $--color-primary;
    }
  }
  .filter-btn {
    position: absolute;
    right: 14px;
    top: 72px;
    z-index: 1;
    color: $--color-primary;
    font-size: 16px;
  }

  .urge-tag {
    color: #f3961c;
    background: #ffeed8;
    border: 1px solid #f3961c;
    padding: 2px 3px;
    border-radius: 2px;
    font-size: 12px;
    margin-right: 5px;
    vertical-align: middle;
  }
}
</style>
